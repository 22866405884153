import styled from 'styled-components'
import { animated } from 'react-spring'

import {
  space,
  color,
  width,
  padding,
  flex,
  alignSelf,
  flexWrap,
  flexDirection,
  alignItems,
  justifyContent,
  textAlign,
  fontSize,
  SpaceProps,
  WidthProps,
  ColorProps,
  FlexProps,
  FlexWrapProps,
  FlexDirectionProps,
  AlignItemsProps,
  JustifyContentProps,
  AlignSelfProps,
  TextAlignProps,
  FontSizeProps,
} from 'styled-system'
import { TextInputProps } from 'react-native';

type BoxProps = SpaceProps & WidthProps & ColorProps & FlexProps & AlignSelfProps & TextAlignProps & FontSizeProps

export const Box = styled.div<BoxProps>`
  box-sizing: border-box;
  ${space}
  ${width}
  ${color}
  ${flex}
  ${alignSelf}
  ${textAlign}
  ${fontSize}
`

Box.displayName = 'Box'

type AnimatedBoxProps = SpaceProps & WidthProps & ColorProps & FlexProps & AlignSelfProps & TextAlignProps

export const AnimatedBox = styled(animated.div)<AnimatedBoxProps>`
  box-sizing: border-box;
  ${space}
  ${width}
  ${color}
  ${flex}
  ${alignSelf}
  ${textAlign}
`

AnimatedBox.displayName = 'AnimatedBox'

type FlexCompProps = SpaceProps & FlexWrapProps & FlexDirectionProps & AlignItemsProps & JustifyContentProps

export const Flex = styled.div<FlexCompProps>`
  display: flex;
  ${space}
  ${flexWrap}
  ${flexDirection}
  ${alignItems}
  ${justifyContent}
`

Flex.displayName = 'Flex'

type AnimatedFlexCompProps = SpaceProps & FlexWrapProps & FlexDirectionProps & AlignItemsProps & JustifyContentProps

export const AnimatedFlex = styled(animated.div)<AnimatedFlexCompProps>`
  display: flex;
  ${space}
  ${flexWrap}
  ${flexDirection}
  ${alignItems}
  ${justifyContent}
`

AnimatedFlex.displayName = 'AnimatedFlex'

type ButtonProps = SpaceProps & WidthProps & ColorProps 

export const Button = styled.button<ButtonProps>`
  border-radius: 1000rem;
  border: none;
  font-weight: 700;
  font-size: 1.25rem;
  &:hover {
    cursor: pointer;
  }
  ${space}
  ${width}
  ${color}
  ${padding}
`

Button.displayName = 'Button'

export const Input = styled.input<TextInputProps>`
  background-color: #f0f1f2;
  border-radius: 10px;
  border: none;
  margin: 0.5em;
  padding: 0.5em;
  width: 100%;
  box-sizing: border-box;
  grid-column: 2;
  grid-row: auto;

  @media (max-width: ${props => props.theme.breakpoints[2]}) {
    grid-column: 1;
    margin: 0.2em;
  }
  `
Input.displayName = 'Input'

export const TextArea = styled.textarea`
  font-size: 1em;
  margin: 0.5em;
  padding: 0.5em;
  background-color: #f0f1f2;
  border-radius: 10px;
  border: none;
  width: 100%;
  float:left;
  box-sizing: border-box;
  grid-column: 2;
  grid-row: auto;
  min-height: calc(100% - 2em);

  @media (max-width: ${props => props.theme.breakpoints[2]}) {
    grid-column: 1;
    margin: 0.2em;
  } 
`
TextArea.displayName = 'TextArea'

export const Form = styled.form`
  display: grid;
  grid-template-columns: auto 1fr 1fr;
  grid-auto-flow: row dense;
  grid-gap: .8em;
  padding: 1.2em;

  @media (max-width: ${props => props.theme.breakpoints[2]}) {
    grid-template-columns: 1fr;
    grid-gap: .2em;
  }
`
Form.displayName = 'Form'

export const Label = styled.label`
  margin: 0.5em;
  grid-column: 1;
  grid-row: auto;

  @media (max-width: ${props => props.theme.breakpoints[2]}) {
    margin: 0.2em;
  }
`
Label.displayName = 'Label'

