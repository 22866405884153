import React from 'react'

const Logo = () => (
  <svg viewBox="0 0 2000 2000" xmlns="http://www.w3.org/2000/svg">
    <circle fill="#FBF9C6" cx="999.5" cy="1000.3" r="999.7"/>
      <circle fill="#FDC69D" cx="999.5" cy="616.9" r="616.3"/>
      <circle fill="#CC533D" cx="1016.9" cy="384.1" r="383.4"/>
      </svg>
)

export default Logo
